var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.$route.name == 'add-faqs' ? 'Add FAQ' : 'Edit FAQ',"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"forum",attrs:{"enctype":"multipart/form-data"}},[_vm._l((_vm.form.faqs),function(faq,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('b-form-group',[_c('label',[_vm._v("Question "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.submitted &&
                        !_vm.$v.form.faqs.$each[index].question.$model,
                    },attrs:{"placeholder":"Enter question"},model:{value:(faq.question),callback:function ($$v) {_vm.$set(faq, "question", $$v)},expression:"faq.question"}}),(
                      _vm.submitted && !_vm.$v.form.faqs.$each[index].question.$model
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Question is required. ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-4"},[_c('b-form-group',[_c('label',{attrs:{"for":"answer"}},[_vm._v("Answer "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.submitted && !_vm.$v.form.faqs.$each[index].answer.$model,
                    },attrs:{"id":"answer","placeholder":"Enter answer"},model:{value:(faq.answer),callback:function ($$v) {_vm.$set(faq, "answer", $$v)},expression:"faq.answer"}}),(
                      _vm.submitted && !_vm.$v.form.faqs.$each[index].answer.$model
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Answer is required. ")]):_vm._e()],1)],1),(_vm.$route.name == 'add-faqs')?_c('div',{staticClass:"col-2",staticStyle:{"margin-top":"27px"}},[(_vm.form.faqs.length - 1 == index)?_c('b-button',{staticClass:"mr-3",attrs:{"variant":"success","disabled":_vm.form.faqs[index].question == 0},on:{"click":function($event){$event.preventDefault();return _vm.addFaq(index)}}},[_vm._v("Add faqs")]):_vm._e(),_c('b-button',{attrs:{"disabled":_vm.form.faqs.length <= 1,"variant":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.removeFaq(index)}}},[_vm._v("Remove faqs")])],1):_vm._e(),_c('div',{staticClass:"col-2 d-flex align-items-center"},[_c('b-form-group',{staticClass:"col-1 d-flex align-items-center mb-0"},[_c('b-form-checkbox',{model:{value:(faq.is_active),callback:function ($$v) {_vm.$set(faq, "is_active", $$v)},expression:"faq.is_active"}},[_vm._v(" Active ")])],1)],1)])}),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-faqs')?_c('span',[_vm._v(" Save Data ")]):_c('span',[_vm._v(" Update Data ")])])],2)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }