<template>
  <Layout>
    <PageHeader
      :title="$route.name == 'add-faqs' ? 'Add FAQ' : 'Edit FAQ'"
      :items="items"
    >
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="forum">
              <div class="row" v-for="(faq, index) in form.faqs" :key="index">
                <div class="col-4">
                  <b-form-group>
                    <label>Question <span style="color: red">*</span></label>
                    <b-form-input
                      v-model="faq.question"
                      placeholder="Enter question"
                      :class="{
                        'is-invalid':
                          submitted &&
                          !$v.form.faqs.$each[index].question.$model,
                      }"
                    ></b-form-input>
                    <div
                      v-if="
                        submitted && !$v.form.faqs.$each[index].question.$model
                      "
                      class="invalid-feedback"
                    >
                      Question is required.
                    </div>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <label for="answer"
                      >Answer <span style="color: red">*</span></label
                    >
                    <b-form-input
                      id="answer"
                      v-model="faq.answer"
                      placeholder="Enter answer"
                      :class="{
                        'is-invalid':
                          submitted && !$v.form.faqs.$each[index].answer.$model,
                      }"
                    ></b-form-input>
                    <div
                      v-if="
                        submitted && !$v.form.faqs.$each[index].answer.$model
                      "
                      class="invalid-feedback"
                    >
                      Answer is required.
                    </div>
                  </b-form-group>
                </div>
                <div
                  class="col-2"
                  style="margin-top: 27px"
                  v-if="$route.name == 'add-faqs'"
                >
                  <b-button
                    variant="success"
                    class="mr-3"
                    v-if="form.faqs.length - 1 == index"
                    @click.prevent="addFaq(index)"
                    :disabled="form.faqs[index].question == 0"
                    >Add faqs</b-button
                  >
                  <b-button
                    :disabled="form.faqs.length <= 1"
                    variant="danger"
                    @click.prevent="removeFaq(index)"
                    >Remove faqs</b-button
                  >
                </div>
                <div class="col-2 d-flex align-items-center">
                  <b-form-group class="col-1 d-flex align-items-center mb-0">
                    <b-form-checkbox v-model="faq.is_active">
                      Active
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
              >
                <span v-if="this.$route.name == 'add-faqs'"> Save Data </span>
                <span v-else> Update Data </span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "@/mixins/request";
import clinicMixin from "@/mixins/ModuleJs/faq";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Layout,
    PageHeader,
  },
  mixins: [MixinRequest, clinicMixin],
  data() {
    return {
      submitted: false,
      items: [
        {
          text: "Back",
          href: `/clinic/edit-clinic/${this.$route.params.id}`,
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      faqs: {
        $each: {
          question: { required },
          answer: { required },
        },
      },
    },
  },
  created() {
    if (this.$route.name == "edit-faqs")
      this.fetchSingleHospital(this.$route.params.id);
  },
};
</script>
